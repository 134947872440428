var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "inspectManagerPointForm-container" },
    [
      _c(
        "form-panel",
        _vm._b(
          {
            ref: "formPanel",
            attrs: { form: _vm.form, submitBefore: _vm.submitBefore },
            on: { update: _vm.update },
          },
          "form-panel",
          _vm.submitConfig,
          false
        ),
        [
          _c(
            "col2-detail",
            [
              _c(
                "col2-block",
                { attrs: { title: "规则信息" } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "所属项目",
                        prop: "communityId",
                        rules: {
                          required: true,
                          message: "请选择所属项目",
                          trigger: "change",
                        },
                      },
                    },
                    [
                      _c(
                        "v-select2",
                        _vm._b(
                          {
                            attrs: {
                              placeholder: "查询项目",
                              width: _vm.width,
                              disabled: !_vm.isAdd,
                            },
                            on: { onChange: _vm.communityIdChange },
                            model: {
                              value: _vm.form.communityId,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "communityId", $$v)
                              },
                              expression: "form.communityId",
                            },
                          },
                          "v-select2",
                          _vm.communityParams,
                          false
                        )
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "所属组团" } },
                    [
                      _c("v-select", {
                        attrs: { options: _vm.blockNameOps, width: _vm.width },
                        model: {
                          value: _vm.form.blockName,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "blockName", $$v)
                          },
                          expression: "form.blockName",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm.form.type === 1
                    ? _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "巡查地点",
                            prop: "site",
                            rules: [
                              {
                                required: true,
                                message: "请选输入巡查地点",
                                trigger: "blur",
                              },
                            ],
                          },
                        },
                        [
                          _c("v-input", {
                            attrs: {
                              width: _vm.width,
                              placeholder: "请输入巡查地点",
                            },
                            model: {
                              value: _vm.form.site,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "site", $$v)
                              },
                              expression: "form.site",
                            },
                          }),
                        ],
                        1
                      )
                    : _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "巡查地点",
                            prop: "roomId",
                            rules: [
                              {
                                required: true,
                                message: "请选择巡查地点",
                                trigger: "change",
                              },
                            ],
                          },
                        },
                        [
                          _c(
                            "v-select2",
                            _vm._b(
                              {
                                attrs: {
                                  placeholder: "查询巡查地点",
                                  subjoin: _vm.communityIdParams,
                                  width: _vm.width,
                                },
                                model: {
                                  value: _vm.form.roomId,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "roomId", $$v)
                                  },
                                  expression: "form.roomId",
                                },
                              },
                              "v-select2",
                              _vm.roomIdParams,
                              false
                            )
                          ),
                        ],
                        1
                      ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "巡查类型",
                        prop: "type",
                        rules: [
                          {
                            required: true,
                            message: "当前选项不允许为空",
                            trigger: "change",
                          },
                        ],
                      },
                    },
                    [
                      _c("v-select", {
                        attrs: {
                          options: _vm.typeOps,
                          width: _vm.width,
                          disabled: !_vm.isAdd,
                        },
                        on: { change: _vm.typeChange },
                        model: {
                          value: _vm.form.type,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "type", $$v)
                          },
                          expression: "form.type",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "巡查分类", required: "" } },
                    [
                      _c(
                        "div",
                        { staticClass: "content-wrapper" },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                prop: "firstCategoryId",
                                rules: {
                                  required: true,
                                  message: "当前选项不允许为空",
                                  trigger: "change",
                                },
                              },
                            },
                            [
                              _c("v-select", {
                                staticClass: "right-wrapper",
                                attrs: {
                                  options: _vm.firstOps,
                                  width: _vm.width,
                                },
                                on: { change: _vm.firstCategoryChange },
                                model: {
                                  value: _vm.form.firstCategoryId,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "firstCategoryId", $$v)
                                  },
                                  expression: "form.firstCategoryId",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                prop: "categoryId",
                                rules: {
                                  required: true,
                                  message: "内容不允许为空",
                                  trigger: "blur",
                                },
                              },
                            },
                            [
                              _c("v-select", {
                                attrs: {
                                  options: _vm.secondOps,
                                  width: _vm.width,
                                },
                                on: { change: _vm.secondCategoryChange },
                                model: {
                                  value: _vm.form.categoryId,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "categoryId", $$v)
                                  },
                                  expression: "form.categoryId",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "巡查要点", required: "" } },
                    _vm._l(_vm.form.contents, function (item, index) {
                      return _c(
                        "el-form-item",
                        {
                          key: index,
                          staticClass: "content-wrapper",
                          attrs: {
                            prop: "contents." + index + ".content",
                            rules: {
                              required: true,
                              message: "要点内容不允许为空",
                              trigger: "change",
                            },
                          },
                        },
                        [
                          _c("v-input", {
                            staticClass: "first-margin",
                            attrs: {
                              placeholder: "请输入要点内容",
                              width: _vm.width,
                              maxlength: 300,
                            },
                            model: {
                              value: item.content,
                              callback: function ($$v) {
                                _vm.$set(item, "content", $$v)
                              },
                              expression: "item.content",
                            },
                          }),
                          _vm.form.contents.length !== 1
                            ? _c("v-button", {
                                staticClass: "second-margin",
                                attrs: { type: "danger", text: "删除" },
                                on: {
                                  click: function ($event) {
                                    return _vm.plusMinusAction(false, index)
                                  },
                                },
                              })
                            : _vm._e(),
                          index === 0
                            ? _c("v-button", {
                                attrs: { text: "添加巡查要点" },
                                on: {
                                  click: function ($event) {
                                    return _vm.plusMinusAction(true, 0)
                                  },
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      )
                    }),
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "巡查频次", required: "" } },
                    [
                      _c(
                        "div",
                        { staticClass: "content-wrapper" },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                prop: "period",
                                rules: {
                                  required: true,
                                  message: "当前选项不允许为空",
                                  trigger: "change",
                                },
                              },
                            },
                            [
                              _c("v-select", {
                                staticClass: "right-wrapper",
                                attrs: {
                                  options: _vm.periodOps,
                                  width: _vm.width,
                                },
                                on: { change: _vm.changePeriod },
                                model: {
                                  value: _vm.form.period,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "period", $$v)
                                  },
                                  expression: "form.period",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                prop: "periodNum",
                                rules: {
                                  required: true,
                                  message: "巡查频次不允许为空",
                                  trigger: "blur",
                                },
                              },
                            },
                            [
                              _c("v-input-number", {
                                attrs: {
                                  controls: "",
                                  "controls-position": "right",
                                  placeholder: "7",
                                  min: 0,
                                  max: 99,
                                  width: _vm.width,
                                },
                                model: {
                                  value: _vm.form.periodNum,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "periodNum", $$v)
                                  },
                                  expression: "form.periodNum",
                                },
                              }),
                            ],
                            1
                          ),
                          _c("span", { staticClass: "right-tip" }, [
                            _vm._v("如：“日、3”表示每日巡查3次"),
                          ]),
                        ],
                        1
                      ),
                    ]
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "巡查间隔", required: "" } },
                    [
                      _c(
                        "div",
                        { staticClass: "content-wrapper" },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                prop: "timespan",
                                rules: {
                                  required: true,
                                  message: "巡查间隔不允许为空",
                                  trigger: "blur",
                                },
                              },
                            },
                            [
                              _c("v-input-number", {
                                staticClass: "right-wrapper",
                                attrs: {
                                  controls: "",
                                  "controls-position": "right",
                                  placeholder: "1",
                                  min: 0,
                                  max: 9999999999,
                                  width: _vm.width,
                                },
                                model: {
                                  value: _vm.form.timespan,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "timespan", $$v)
                                  },
                                  expression: "form.timespan",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                prop: "timespanUnit",
                                rules: {
                                  required: true,
                                  message: "当前选项不允许为空",
                                  trigger: "change",
                                },
                              },
                            },
                            [
                              _c("v-select", {
                                attrs: {
                                  options: _vm.timespanUnitOps,
                                  width: _vm.width,
                                },
                                model: {
                                  value: _vm.form.timespanUnit,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "timespanUnit", $$v)
                                  },
                                  expression: "form.timespanUnit",
                                },
                              }),
                            ],
                            1
                          ),
                          _c("span", { staticClass: "right-tip" }, [
                            _vm._v("两次巡查间隔最小时间"),
                          ]),
                        ],
                        1
                      ),
                    ]
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "巡查方式",
                        prop: "pattern",
                        rules: [
                          {
                            required: true,
                            message: "请选择巡查方式",
                            trigger: "change",
                          },
                        ],
                      },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "content-wrapper" },
                        [
                          _c("v-select", {
                            attrs: {
                              options: _vm.patternOps,
                              width: _vm.width,
                            },
                            model: {
                              value: _vm.form.pattern,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "pattern", $$v)
                              },
                              expression: "form.pattern",
                            },
                          }),
                          _c(
                            "div",
                            { staticClass: "right-tip" },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "是否允许相册上传图片",
                                    prop: "allowAlbumUpload",
                                  },
                                },
                                [
                                  _c("el-switch", {
                                    attrs: {
                                      "active-value": 1,
                                      "inactive-value": 0,
                                      "active-color": "#409EFF",
                                    },
                                    model: {
                                      value: _vm.form.allowAlbumUpload,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.form,
                                          "allowAlbumUpload",
                                          $$v
                                        )
                                      },
                                      expression: "form.allowAlbumUpload",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm.form.pattern === 2
                            ? _c(
                                "div",
                                { staticClass: "right-tip jump" },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "是否允许执行任务跳过扫描二维码",
                                        prop: "skipScan",
                                      },
                                    },
                                    [
                                      _c("el-switch", {
                                        attrs: {
                                          "active-value": 1,
                                          "inactive-value": 0,
                                          "active-color": "#409EFF",
                                        },
                                        model: {
                                          value: _vm.form.skipScan,
                                          callback: function ($$v) {
                                            _vm.$set(_vm.form, "skipScan", $$v)
                                          },
                                          expression: "form.skipScan",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ]
                  ),
                  _vm.form.pattern === 1
                    ? _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "蓝牙编号",
                            prop: "bluetoothId",
                            rules: [
                              {
                                required: true,
                                message: "请选择蓝牙编号",
                                trigger: "change",
                              },
                            ],
                          },
                        },
                        [
                          _c(
                            "v-select2",
                            _vm._b(
                              {
                                attrs: {
                                  placeholder: "查询蓝牙编号",
                                  subjoin: _vm.communityIdParams,
                                  width: _vm.width,
                                },
                                model: {
                                  value: _vm.form.bluetoothId,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "bluetoothId", $$v)
                                  },
                                  expression: "form.bluetoothId",
                                },
                              },
                              "v-select2",
                              _vm.bluetoothIdParams,
                              false
                            )
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "规则生效时间",
                        prop: "effectStartTime",
                        rules: [
                          {
                            required: true,
                            message: "请选择规则生效时间",
                            trigger: "blur",
                          },
                        ],
                      },
                    },
                    [
                      _c("v-datepicker", {
                        attrs: {
                          type: "date",
                          disabledDays: _vm.disabledDate,
                          width: _vm.width,
                        },
                        model: {
                          value: _vm.form.effectStartTime,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "effectStartTime", $$v)
                          },
                          expression: "form.effectStartTime",
                        },
                      }),
                      _c("div", [
                        _vm._v(
                          "巡检规则将在该周期内有效,项目巡查将自动生成巡查计划。"
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }