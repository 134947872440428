<template>
  <div class="inspectManagerPointForm-container">
    <form-panel
      ref="formPanel"
      :form="form"
      v-bind="submitConfig"
      :submitBefore="submitBefore"
      @update="update"
    >
      <col2-detail>
        <col2-block title="规则信息">
          <el-form-item
            label="所属项目"
            prop="communityId"
            :rules="{ required: true,  message: '请选择所属项目', trigger: 'change' }"
          >
            <v-select2
              placeholder="查询项目"
              v-model="form.communityId"
              v-bind="communityParams"
              :width="width"
              :disabled="!isAdd"
              @onChange="communityIdChange"
            ></v-select2>
          </el-form-item>
          <el-form-item label="所属组团">
            <v-select
              v-model="form.blockName"
              :options="blockNameOps"
              :width="width"
            ></v-select>
          </el-form-item>
          <el-form-item
            v-if="form.type === 1"
            label="巡查地点"
            prop="site"
            :rules="[
              { required: true, message: '请选输入巡查地点', trigger: 'blur' }
            ]"
          >
            <v-input
              v-model="form.site"
              :width="width"
              placeholder="请输入巡查地点"
            ></v-input>
          </el-form-item>
          <el-form-item
            v-else
            label="巡查地点"
            prop="roomId"
            :rules="[
              { required: true, message: '请选择巡查地点', trigger: 'change' }
            ]"
          >
            <v-select2
              placeholder="查询巡查地点"
              v-model="form.roomId"
              v-bind="roomIdParams"
              :subjoin="communityIdParams"
              :width="width"
            ></v-select2>
          </el-form-item>
          <el-form-item
            label="巡查类型"
            prop="type"
            :rules="[  { required: true, message: '当前选项不允许为空', trigger: 'change' }
            ]"
          >
            <v-select
              v-model="form.type"
              :options="typeOps"
              :width="width"
              :disabled="!isAdd"
              @change="typeChange"
            ></v-select>
          </el-form-item>
          <el-form-item label="巡查分类" required>
            <div class="content-wrapper">
              <el-form-item
                prop="firstCategoryId"
                :rules="{ required: true, message: '当前选项不允许为空', trigger: 'change'  }"
              >
                <v-select
                    class="right-wrapper"
                    v-model="form.firstCategoryId"
                    :options="firstOps"
                    :width="width"
                    @change="firstCategoryChange"
                ></v-select>
              </el-form-item>
              <el-form-item
                prop="categoryId"
                :rules="{ required: true, message: '内容不允许为空', trigger: 'blur' }"
              >
                <v-select
                    v-model="form.categoryId"
                    :options="secondOps"
                    :width="width"
                    @change="secondCategoryChange"
                ></v-select>
              </el-form-item>
            </div>
          </el-form-item>
          <el-form-item label="巡查要点" required>
            <el-form-item
              class="content-wrapper"
              v-for="(item, index) in form.contents"
              :key="index"
              :prop="'contents.' + index + '.content'"
              :rules="{
                required: true,
                message: '要点内容不允许为空',
                trigger: 'change'
              }"
            >
              <v-input
                class="first-margin"
                v-model="item.content"
                placeholder="请输入要点内容"
                :width="width"
                :maxlength="300"
              ></v-input>
              <v-button
                v-if="form.contents.length !== 1"
                class="second-margin"
                type="danger"
                text="删除"
                @click="plusMinusAction(false, index)"
              ></v-button>
              <v-button
                v-if="index === 0"
                text="添加巡查要点"
                @click="plusMinusAction(true, 0)"
              ></v-button>
            </el-form-item>
          </el-form-item>
          <el-form-item label="巡查频次" required>
            <div class="content-wrapper">
              <el-form-item
                prop="period"
                :rules="{ required: true, message: '当前选项不允许为空', trigger: 'change'  }"
              >
                <v-select
                  class="right-wrapper"
                  v-model="form.period"
                  :options="periodOps"
                  :width="width"
                  @change="changePeriod"
                ></v-select>
              </el-form-item>
              <el-form-item
                prop="periodNum"
                :rules="{ required: true, message: '巡查频次不允许为空', trigger: 'blur' }"
              >
                <v-input-number
                  controls
                  v-model="form.periodNum"
                  controls-position="right"
                  placeholder="7"
                  :min="0"
                  :max="99"
                  :width="width"
                ></v-input-number>
              </el-form-item>
              <span class="right-tip">如：“日、3”表示每日巡查3次</span>
            </div>
          </el-form-item>
          <el-form-item label="巡查间隔" required>
            <div class="content-wrapper">
              <el-form-item
                prop="timespan"
                :rules="{ required: true, message: '巡查间隔不允许为空', trigger: 'blur' }"
              >
                <v-input-number
                  controls
                  v-model="form.timespan"
                  class="right-wrapper"
                  controls-position="right"
                  placeholder="1"
                  :min="0"
                  :max="9999999999"
                  :width="width"
                ></v-input-number>
              </el-form-item>
              <el-form-item
                prop="timespanUnit"
                :rules="{ required: true,  message: '当前选项不允许为空', trigger: 'change' }"
              >
                <v-select
                  v-model="form.timespanUnit"
                  :options="timespanUnitOps"
                  :width="width"
                ></v-select>
              </el-form-item>
              <span class="right-tip" >两次巡查间隔最小时间</span
              >
            </div>
          </el-form-item>
          <el-form-item
            label="巡查方式"
            prop="pattern"
            :rules="[
              { required: true, message: '请选择巡查方式', trigger: 'change' }
            ]"
          >
            <div class="content-wrapper">
              <v-select v-model="form.pattern" :options="patternOps" :width="width"></v-select>
              <div class="right-tip">
                <el-form-item label="是否允许相册上传图片" prop="allowAlbumUpload">
                  <el-switch v-model="form.allowAlbumUpload" :active-value="1" :inactive-value="0" active-color="#409EFF"/>
                </el-form-item>               
              </div>
              <div class="right-tip jump" v-if="form.pattern === 2">
                <el-form-item label="是否允许执行任务跳过扫描二维码" prop="skipScan">
                  <el-switch v-model="form.skipScan" :active-value="1" :inactive-value="0" active-color="#409EFF"/>
                </el-form-item>
              </div>
            </div>           
          </el-form-item>
          <el-form-item
            v-if="form.pattern === 1"
            label="蓝牙编号"
            prop="bluetoothId"
            :rules="[
              { required: true, message: '请选择蓝牙编号', trigger: 'change' }
            ]"
          >
            <v-select2
              placeholder="查询蓝牙编号"
              v-model="form.bluetoothId"
              v-bind="bluetoothIdParams"
              :subjoin="communityIdParams"
              :width="width"
            ></v-select2>
          </el-form-item>
           <el-form-item
            label="规则生效时间"
            prop="effectStartTime"
            :rules="[
              { required: true, message: '请选择规则生效时间', trigger: 'blur' }
            ]"
          >
            <v-datepicker
              type="date"
              v-model="form.effectStartTime"
              :disabledDays="disabledDate"
              :width="width"
            ></v-datepicker>
            <div>巡检规则将在该周期内有效,项目巡查将自动生成巡查计划。</div>
          </el-form-item>
        </col2-block>
      </col2-detail>
    </form-panel>
  </div>
</template>

<script>
import * as allPointUrl from './api'
import {
  typeOps,
  periodFormOps,
  timespanUnitFormOps,
  patternFormOps
} from './map'
import { Col2Block, Col2Detail } from 'components/bussiness'
import { normalCommunityParams } from 'common/select2Params'
import { select2BlockURL } from 'common/api'

export default {
  components: {
    Col2Block,
    Col2Detail
  },
  data () {
    return {
      width: 180,
      form: {
        id: undefined,
        communityId: '',
        blockName: undefined,
        site: '',
        pattern: 2,
        // 频次类型
        period: 1,
        // 频次
        periodNum: undefined,
        // 间隔时间
        timespan: undefined,
        // 间隔类型 1小时/2日
        timespanUnit: 1,
        roomId: '',
        bluetoothId: '',
        effectStartTime: '',
        categoryId: undefined,
        content: [''],
        // 默认取typeOps里第一个
        type: this.getDefaultType(),
        contents: [{ content: '' }],
        firstCategoryId: undefined,
        allowAlbumUpload: 1,
        skipScan: 0
      },
      roomIdParams: {
        searchUrl: allPointUrl.getRoomUrl,
        request: {
          text: 'roomAddress',
          value: 'roomId'
        },
        response: {
          text: 'roomAddress'
        }
      },
      communityParams: normalCommunityParams,
      bluetoothIdParams: {
        searchUrl: allPointUrl.getBluetoothIdUrl,
        method: 'post',
        request: {
          text: 'serialNum',
          value: 'id'
        },
        response: {
          text: 'serialNum'
        }
      },
      typeOps: this.getTypeOps(),
      periodOps: periodFormOps,
      timespanUnitOps: timespanUnitFormOps,
      patternOps: patternFormOps,
      submitConfig: {
        submitMethod: '',
        submitUrl: allPointUrl.handleInspectPointOnOffURL,
        queryUrl: ''
      },
      // 所属组团
      blockNameOps: [],
      isAdd: false,
      firstOps: [],
      secondOps: [],
      allCategoryList: [],
      secondCategoryEcho: false,
      firstCategoryEcho: false,
      periodEcho: false,
      typeEcho: false
    }
  },
  computed: {
    communityIdParams () {
      return {
        communityId: this.form.communityId
      }
    }
  },

  mounted () {
    const { id } = this.$route.query
    this.isAdd = id === undefined
    this.submitConfig.submitMethod = this.isAdd ? 'post' : 'put'
    if (id !== undefined) {
      this.submitConfig.queryUrl = `${allPointUrl.handleInspectPointOnOffURL}/${id}`
      this.$refs.formPanel.getData()
    } else {
      this.requestForCategory(false)
    }
    let title = this.isAdd ? '新增' : '编辑'
    // 设置title
    this.$setBreadcrumb(title)
  },

  methods: {
    update (data) {
      this.secondCategoryEcho = true
      this.periodEcho = true
      this.typeEcho = true
      let contentList = data.contentList || []
      let contents = contentList.map(item => {
        return { content: item }
      })
      data.contents = contents && contents.length ? contents : [{ content: '' }]
      data.blockName = !data.blockName || data.blockName.length === 0 ? '无' : data.blockName
      data.id = data.pointId
      Object.keys(this.form).forEach(key => {
        this.form[key] = data[key]
      })
      this.requestForCategory(true)
      this.disabledDate()
    },
    submitBefore (data) {
      let tempList = []
      let contents = data.contents
      contents.forEach(item => {
        tempList.push(item.content)
      })
      data.content = JSON.stringify(tempList)

      let period = data.period
      let timespanUnit = data.timespanUnit
      let time = data.periodNum * data.timespan
      if (timespanUnit === 1) {
        if (
          (period === 1 && time > 1 * 24) ||
          (period === 2 && time > 28 * 24) ||
          (period === 3 && time > 7 * 24) ||
          (period === 4 && time > 89 * 24)
        ) {
          this.$alert('保存失败，巡查频次和巡查间隔设置有误，请重新输入')
          return false
        }
      } else if (timespanUnit === 2) {
        if (
          (period === 1 && time !== 1) ||
          (period === 2 && time > 28) ||
          (period === 3 && time > 7) ||
          (period === 4 && time > 89)
        ) {
          this.$alert('保存失败，巡查频次和巡查间隔设置有误，请重新输入')
          return false
        }
      }
      delete data.contents
      delete data.firstCategoryId
      return true
    },

    // 获取巡查类型
    getTypeOps () {
      let tempTypeOps = typeOps()
      const { id } = this.$route.query
      return id !== undefined ? tempTypeOps : tempTypeOps.slice(0, 2)
    },
    // 获取默认type
    getDefaultType () {
      let typeItem = this.getTypeOps()[0]
      return typeItem.value
    },

    // 项目改变
    communityIdChange (val, echo) {
      if (!echo) {
        // 获取分类
        if (val) {
          this.requestForCategory(false)
          this.getInspectPointConfigByCommunityId(val.id)
        } else {
          // TODO 清空已选的分类
        }
      }
      // 获取组团信息
      this.requestForBlockNameData(val, echo)
    },

    // 巡查类型改变
    typeChange (value) {
      if (this.typeEcho) {
        this.typeEcho = false
      } else {
        this.requestForCategory(false)
      }
    },

    // 一级分类改变
    firstCategoryChange (val, ops) {
      if (this.firstCategoryEcho) {
        this.firstCategoryEcho = false
      } else {
        this.getSecondLevelList(false)
      }
    },

    // 二级分类改变
    secondCategoryChange (val, ops) {
      if (this.secondCategoryEcho) {
        this.secondCategoryEcho = false
      } else {
        if (val) {
          // 有可能为0 要默认值1
          let period = ops.period || 1
          let timespanUnit = ops.timespanUnit || 1
          this.$set(this.form, 'period', period)
          this.$set(this.form, 'periodNum', ops.periodNum)
          this.$set(this.form, 'timespan', ops.timespan)
          this.$set(this.form, 'timespanUnit', timespanUnit)

          // 重新获取要点信息
          this.requestForGetCategoryContent()
        } else {
          // 清空要点信息
          this.form.contents = [{ content: '' }]
        }
      }
    },

    plusMinusAction (add, index) {
      if (add) {
        // 添加
        let num = 100;
        if (this.form.contents.length < num) {
          this.form.contents.push({
            content: ''
          })
        } else {
          this.$message(`最多只能添加${ num }个巡查要点`)
        }
      } else {
        // 减少
        this.form.contents.splice(index, 1)
      }
    },

    changePeriod (value) {
      if (this.periodEcho) {
        this.periodEcho = false
      } else {
        this.form.effectStartTime = ''
      }
      this.disabledDate()
    },

    disabledDate (time) {
      let self = this
      if (time && time.getTime() > Date.now() - 8.64e7) {
        if (self.form.period === 1) {
          return time.getTime() < Date.now() - 8.64e7
        } else if (self.form.period === 3) {
          return time.getDay() !== 1
        } else {
          return time.getDate() !== 1
        }
      } else {
        return true
      }
    },

    // 获取一级分类数组
    getFirstLevelList (echo) {
      if (this.allCategoryList.length) {
        let tempList = []
        this.allCategoryList.forEach(category => {
          if (category.level === 1) {
            let tempCategory = Object.assign({
              text: category.name,
              value: category.categoryId
            }, category)
            tempList.push(tempCategory)
          }
        })
        this.firstOps = tempList
        if (echo) {
          // 代表回显，要根据二级分类id找到一级分类id
          this.allCategoryList.forEach(item => {
            if (item.categoryId === this.form.categoryId) {
              this.firstCategoryEcho = true
              this.form.firstCategoryId = item.parentId
            }
          })
        } else {
          // 每次更改，默认取第一个
          if (tempList.length) {
            let firstCategoryId = tempList[0].value
            this.form.firstCategoryId = firstCategoryId
          } else {
            this.form.firstCategoryId = undefined
          }
        }
        // 然后获取二级分类
        this.getSecondLevelList(echo)
      } else {
        this.firstOps = []
        this.secondOps = []
        this.form.firstCategoryId = undefined
        this.form.categoryId = undefined
      }
    },

    // 获取二级分类数组
    getSecondLevelList (echo) {
      if (this.allCategoryList.length) {
        let tempList = []
        if (this.form.firstCategoryId !== undefined) {
          // 一级分类存在
          this.allCategoryList.forEach(category => {
            if (category.level === 2 && this.form.firstCategoryId === category.parentId) {
              let tempCategory = Object.assign({
                text: category.name,
                value: category.categoryId
              }, category)
              tempList.push(tempCategory)
            }
          })
        }
        this.secondOps = tempList
        if (!echo) {
          // 每次更改，默认取第一个
          if (tempList.length) {
            let categoryId = tempList[0].value
            this.form.categoryId = categoryId
          } else {
            // 对应的二级分类数组不存在，二级分类id也要置为undefined
            this.form.categoryId = undefined
          }
        } else {
          // 回显的时候，若是没有找到对应的一级分类，二级分类id也要置为undefined
          if (!this.form.firstCategoryId || tempList.length === 0) {
            this.form.categoryId = undefined
          }
        }
      } else {
        this.secondOps = []
        this.form.categoryId = undefined
      }
    },

    // 请求数据相关函数
    // 根据项目获取组团信息
    requestForBlockNameData (val, echo) {
      if (val) {
        let _this = this
        this.$axios.get(select2BlockURL, { params: { communityId: val.id } }).then(res => {
          if (res.status === 100) {
            let data = res.data
            let blockNameOps = [ { text: '无', value: '无' } ]
            if (data && data.length) {
              data.forEach(item => {
                blockNameOps.push({
                  text: item.name,
                  value: item.name
                })
              })
            }
            _this.blockNameOps = blockNameOps
            if (!echo) {
              /// 如果不是回显，就需要取默认第一个
              _this.form.blockName = blockNameOps[0].value
            }
          }
        })
      } else {
        this.blockNameOps = []
        this.form.blockName = undefined
      }
    },

    // 根据类型获取所有分类
    requestForCategory (echo) {
      let _this = this
      let getParams = {
        params: {
          type: this.form.type,
          communityId: this.form.communityId
        }
      }
      this.$axios
        .get(allPointUrl.getInspectCategoryAllURL, getParams)
        .then(res => {
          if (res.status === 100) {
            // 真对一级二级分类进行处理
            let categoryList = res.data || []
            _this.allCategoryList = categoryList
            _this.getFirstLevelList(echo)
          }
        })
    },

    // 获取巡查要点
    requestForGetCategoryContent () {
      let _this = this
      let requestUrl = `${allPointUrl.getInspectCategoryContentURL}/${this.form.categoryId}`
      this.$axios.get(requestUrl).then(res => {
        if (res.status === 100) {
          // 处理巡查要点
          let tempList = res.data || []
          let contentList = []
          tempList.forEach((item, index) => {
            contentList.push({
              content: item
            })
          })
          if (contentList.length === 0) {
            contentList.push({ content: '' })
          }
          _this.$set(_this.form, 'contents', contentList)
        }
      })
    },
    // 获取巡查点配置
    getInspectPointConfigByCommunityId (id) {
      let requestUrl = `${allPointUrl.getInspectPointConfigByCommunityIdURL}?communityId=${id}`
      this.$axios.get(requestUrl).then(res => {
        if (res.status === 100) {
          let { isAutoGenerateEmptyRoomPoint, isAutoGenerateDecorationPoint,isAutoGenerateUnclaimedRoomPoint, isAutoGenerateVacantRoomPoint } = res.data
          // let ops = this.getTypeOps()
          let ops = typeOps()
          let index = undefined
          if (isAutoGenerateEmptyRoomPoint === 1) {
            index = ops.findIndex(item => item.value === 2)
            ops.splice(index,1)
            this.form.type = 1
          }
          if (isAutoGenerateDecorationPoint === 1) {
            index = ops.findIndex(item => item.value === 3)

            ops.splice(index,1)
            this.form.type = 1
          }
          if (isAutoGenerateVacantRoomPoint === 1) {
            index = ops.findIndex(item => item.value === 4)

            ops.splice(index,1)
            this.form.type = 1
          }
          if (isAutoGenerateUnclaimedRoomPoint === 1) {
            index = ops.findIndex(item => item.value === 5)

            ops.splice(index,1)
            this.form.type = 1
          }
          this.typeOps = ops
        }
      })
    }
  }
}
</script>
<style scoped lang="scss">
.inspectManagerPointForm-container {
  .inlineBlock {
    display: inline-block;
  }

  .category-wrapper {
    display: flex;
    justify-content: flex-start;
    .second-category {
      margin-left: 30px;
    }
  }
  .content-wrapper {
    display: flex;
    margin-bottom: 20px;
    .jump{
      ::v-deep .el-form-item__label{
        width: 240px !important;
      }
      ::v-deep .el-form-item__content{
        margin-left: 240px !important;
      }
    }
    
    .first-margin {
      margin-right: 30px;
    }

    .second-margin {
      margin-right: 20px;
    }

    .right-wrapper {
      margin-right: 30px;
    }

    .right-tip {
      margin-left: 15px;
    }

  }
}
</style>
